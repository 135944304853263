* {
  box-sizing: border-box;
}

@font-face {
  font-family: "NunitoSansBlack";   
  src: url("https://app.appcraft.events/fonts/u3mXB8Wbe5jYHS/assets/oNIraFInhbEctOEc-v4OwqEShW8_.ttf") format("truetype");
} 


body {
  background-color: #111;
  font-family: "Raleway"!important; 
}

.square {
  cursor: pointer;
  font-family: "Raleway", "Helvetica", sans-serif;
  // background: #000;
  color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // &:hover {
  //   .square__image {
  //     transform: scale(1.1);
  //   }
  // }

  &--fullscreen {
    .square__image {
      opacity: 0 !important;
    }
  }
}

.square__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

.square__image {
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // opacity: 0.5;
  z-index: -1;
}

.header {
  padding: 1vw;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.35s ease-in-out;

  &__icon {
    opacity: 0;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.3vw;
    padding: 1vw;
    transition: all 0.35s ease-in-out;
    pointer-events: none;
    // text-align: center;
    // font-size: 8vw;
  }

  &__title {
    font-family: "NunitoSansBlack";
    transition: all 0.35s ease-in-out;
    text-transform: uppercase;
    font-size: 1.3vw;
    font-weight: bold;
  }

  &__picto {
    filter: invert(1); 
    max-width: 17vh; 
  }
}

.square--fullscreen {
  .header {
    padding: 1vw 2vw;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    &__picto {
      display: none; 
    }
    &__icon {
      padding: 1vw 2vw;
      opacity: 1;
      font-size: 2.25vw;
      pointer-events: all;
    }

    &__title {
      padding-left: 3.5vw;
      font-size: 2.5vw;
    }
  }
}

// .header__title {
//   text-align: center;
// }

$color-ratio: 15%;

.square--registration {
  // background: #fbbd08;
  // background: darken(#fbbd08, $color-ratio);
  background: darken(#2991d6, 15%);
  color: white;
}

.square--organization {
  // background: #db2828;
  // background: darken(#db2828, $color-ratio);
  background: darken(#2991d6, 10%);
  color: white;
}

.square--checkin {
  // background: #b5cc18;
  background: darken(#2991d6, 5%);
  color: white;
}

.square--app {
  background: #2991d6;
  // background: darken(#2185d0, $color-ratio);
  color: white;
}

.square--networking {
  // background: #6435c9;
  background: darken(#2991d6, 20%);
  // background: darken(#6435c9, $color-ratio);
  color: white;
}

.square--interaction {
  background: darken(#2991d6, 25%);
  // background: darken(#21ba45, $color-ratio);
  color: white;
  .square__image {
    opacity: 0.5;
  }
}

.square--feedback {
  // background: #21ba45;
  background: darken(#2991d6, 30%);
  color: white;
}

.square--stats {
  // background: darken(#f2711c, $color-ratio);
  background: darken(#2991d6, 35%);
  color: white;
  .square__image {
    opacity: 0.75;
  }
}

.square--live-display {
  // background: darken(#f2711c, $color-ratio);
  background: darken(#2991d6, 40%);
  color: white;
  .square__image {
    opacity: 0.75;
  }
}

.square--backoffice {
  // background: darken(#f2711c, $color-ratio);
  background: darken(#2991d6, 40%);
  color: white;
}

.square--logo {
  text-align: center;
  padding: 3vw;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

ul.list--animated {
  margin: 5vw;
  margin-left: 10vw;
  margin-top: 10vw;

  li {
    opacity: 0;
    font-size: 3vw;
    line-height: 8vw;
  }
}

.square--fullscreen ul {
  li {
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }

  :nth-child(0) {
    transition-delay: 0.75s;
  }
  :nth-child(1) {
    transition-delay: 1s;
  }
  :nth-child(2) {
    transition-delay: 1.25s;
  }
  :nth-child(3) {
    transition-delay: 1.5s;
  }
  :nth-child(4) {
    transition-delay: 1.75s;
  }
  :nth-child(5) {
    transition-delay: 2s;
  }
  :nth-child(6) {
    transition-delay: 2.25s;
  }
}

.video {
  padding: 1vw;

  &--smartphone {
    top: 20vh;
    position: absolute;
    right: 10vw;
    // width: 35vw;
    padding: 0;

    video {
      height: 66.7vh;
      width: 30.5vh;
      // width: 100%;
    }
  }

  &--fullscreen {
    position: absolute;
    left: 0;
    right: 0;
    top: 5.1vw;
    bottom: 0;
    padding: 0;
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

video {
  opacity: 0;
  width: 100%;
}

.square--fullscreen {
  .video {
    .caption {
      opacity: 1;
    }
  }

  video {
    opacity: 1;
    transition: all 0.5s ease-in-out;
    transition-delay: 0.5s;
  }
}

.box--horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    width: 0;
    flex: 1;
  }
}

.caption {
  opacity: 0;
  font-size: 2.5vw;
  font-weight: bold;
  // color: #2174ab;
  background-color: rgba(33, 116, 171, 0.8);
  text-align: center;
  padding: 1.5vw;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.toptitle {
  opacity: 0;
  font-size: 3vw;
  font-weight: bold;
  // color: #2174ab;
  // background-color: rgba(33, 116, 171, 0.8);
  color: #fff;
  text-align: center;
  padding: 1.5vw;
  position: absolute;
  top: 7.5vh;
  left: 0;
  right: 0;

  // transform: translateY(-30vh);
}

.subtitle {
  opacity: 0;
  font-size: 3vw;
  font-weight: bold;
  // color: #2174ab;
  // background-color: rgba(33, 116, 171, 0.8);
  text-align: center;
  padding: 1.5vw;
  position: absolute;
  bottom: 4vh;
  left: 0;
  right: 0;

  transform: translateY(20vh);
}

.shadow {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  // box-shadow: 0 1.4vw 2.8vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  // box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.shadow--white {
  box-shadow: 0 3px 6px rgba(255, 255, 255, 0.16), 0 3px 6px rgba(255, 255, 255, 0.23);
}

.image {
  opacity: 0;

  &--big {
    transform: translateX(-30vw);
    position: absolute;
    left: 2vw;
    // right: 0;
    top: 9.1vw;
    width: 70vw;
    height: 70vh;
  }

  &--gallery {
    position: absolute;
    left: 10vw;
    right: 10vw;
    // right: 0;
    top: 9.1vw;
    height: 70vh;
    display: flex;
    align-items: center;

    .fa {
      padding: 1vw;
      font-size: 4vw;
      background: rgba(0, 0, 0, 0.25);
    }
    .fa-chevron-right {
      position: absolute;
      right: 0;
    }
    .fa-chevron-left {
      position: absolute;
      left: 0;
    }
  }
  &--mobile {
    transform: translateX(30vw);
    position: absolute;
    right: 2vw;
    // right: 0;
    top: 10vw;

    height: 66.7vh;
    width: 37.5vh;
    // width: 25vw;
    // height: 70vh;
  }
}

.tag {
  // display: none;
  opacity: 0;
  align-self: flex-start;
  display: flex; 
  padding: 0.5vw 0.75vw;
  // background-color: #2174ab;
  background-color: rgb(255, 255, 255);
  color: #d37e2e;
  font-weight: bold;
  font-size: 1.7vw;
  border-radius: 4px;
  margin-bottom: 2vw;
  text-align: right;
  justify-content: center;
  transform: translateX(10vw);
}

.images {
  display: none;
}

.square--fullscreen {
  .subtitle {
    opacity: 1;
    transform: translateY(0vh);
    transition-delay: 0.75s;
    transition: opacity 0.5s ease-in-out, transform 0.75s ease-in-out;
  }
  .toptitle {
    opacity: 1;
    // transform: translateY(0vh);
    transition-delay: 0.75s;
    transition: opacity 0.5s ease-in-out, transform 0.75s ease-in-out;
  }

  .images--mobile {
    display: block;
    position: absolute;
    top: 10vw;
    bottom: 10vw;
    left: 5vw;
    right: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .image {
      display: block;
      position: initial;
      top: initial;
      right: initial;
      margin: 1vw;
      width: 27vh;
      height: 48vh;
    }
  }

  .image {
    transition-delay: 0.75s;
    transition: opacity 0.5s ease-in-out, transform 0.75s ease-in-out;
    opacity: 1;
    transform: translateX(0vh) scale(1);

    background-size: cover;
    background-repeat: no-repeat;

    .caption {
      opacity: 1;
    }
  }

  .tags {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 1.5vw;

    :nth-child(0) {
      transition-delay: 0.5s;
    }
    :nth-child(1) {
      transition-delay: 0.75s;
    }
    :nth-child(2) {
      transition-delay: 1s;
    }
    :nth-child(3) {
      transition-delay: 1.25s;
    }
    :nth-child(4) {
      transition-delay: 1.5s;
    }
    :nth-child(5) {
      transition-delay: 1.75s;
    }
    :nth-child(6) {
      transition-delay: 2s;
    }
  }

  .tag {
    // display: block;
    transform: translateX(0);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    opacity: 1;
  }
}

.sections {
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  // align-items: center;
  justify-content: center;
  position: absolute;
  top: 7vw;
  left: 0;
  right: 0;
  // bottom: 0;
}

.square--fullscreen {
  .sections {
    display: flex;
  }
}

.section {
  width: 35vw;
  margin: 3vw 5vw;
  background: #fdfdfd;
  color: #000;
  border-radius: 0.5vw;
  overflow: hidden;

  &__image {
    position: relative;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20vh;
    border-bottom: 1px solid #ddd;
  }

  &__title {
    color: #1d6696;
    font-size: 2.5vw;
    padding: 1vw 2vw;
    font-weight: bold;
  }
  &__subtitle {
    font-size: 1.75vw;
    padding: 1vw 2vw;
    padding-top: 0;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);

  &__back {
    padding: 1vw 2vw;
    opacity: 1;
    font-size: 2.25vw;
  }

  .image--big {
    left: 10vw;
    top: 6vw;
    width: 80vw;
    height: 80vh;
  }
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
}